import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SCRUB = true;

export const init = () => {
    const pinnedMediaSecitons = gsap.utils.toArray(".section-pinned-media");

    pinnedMediaSecitons.forEach((item) => {
        const contentAreas = gsap.utils.toArray(
            item.querySelectorAll(".section-pinned-media__content-area")
        );

        contentAreas.forEach((contentArea, index) => {
            const mediaArea = contentArea.nextSibling;
            const textElement = contentArea.querySelector(".content");

            gsap.fromTo(
                contentArea,
                {
                    opacity: 0,
                    scale: 0.8,
                },
                {
                    scrollTrigger: {
                        trigger: textElement,
                        scrub: SCRUB,
                        start: "top bottom",
                        end: "bottom center",
                        invalidateOnRefresh: true,
                        onEnter: () => {},
                        onLeave: () => {},
                        onEnterBack: () => {},
                        onLeaveBack: () => {},
                    },
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                }
            );

            gsap.set(mediaArea, { gridRow: 1, autoAlpha: 0 });

            ScrollTrigger.matchMedia({
                // desktop
                "(min-width: 1024px)": function () {
                    gsap.fromTo(
                        mediaArea,
                        {
                            autoAlpha: 0,
                        },
                        {
                            scrollTrigger: {
                                trigger: textElement,
                                scrub: SCRUB,
                                start: "top bottom",
                                end: "bottom center",
                                invalidateOnRefresh: true,
                                toggleActions: "play complete reverse reset",
                            },

                            autoAlpha: 1,
                            duration: 1,
                        }
                    );
                },

                // mobile
                "(max-width: 1023px)": function () {
                    gsap.fromTo(
                        mediaArea,
                        {
                            autoAlpha: 0,
                        },
                        {
                            scrollTrigger: {
                                trigger: textElement,
                                scrub: SCRUB,
                                start: "top-=40% bottom",
                                end: "bottom center",
                                invalidateOnRefresh: true,
                                toggleActions: "play complete reverse reset",
                            },

                            autoAlpha: 1,
                            duration: 1,
                        }
                    );
                },

                // all
                all: function () {
                    // ScrollTriggers created here aren't associated with a particular media query,
                    // so they persist.
                },
            });
        });
    });
};

export default init;
